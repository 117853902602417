<template>
  <Container />
</template>

<script>
import Container from './components/Container.vue';

export default {
    name: 'app',
    components: {
        Container,
    },
};
</script>
